<template>
    <div class="jointly-label-community-page">
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>筛选管理</template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <Button v-show="table_url" type="primary" style="margin-right: 10px" @click="onDisplayForm">分配筛选标签</Button>
                    <Button type="primary" style="margin-right: 10px" @click="$router.push('/jointly/label')">返回</Button>
                </Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <div class="tree-box">
                    <Tree :data="tree_data" :load-data="onLoadTreeData" @on-select-change="onSelectTreeChildren"></Tree>
                </div>
                <!-- 表格 -->
                <elem-table v-if="table_url" ref="elem_table" :columns="perfectedColumns" :table-api="table_url" :operate="operate"></elem-table>
                <div v-else class="unselected">
                    <Icon size="26" type="ios-information-circle-outline" />
                    <p class="text">请先选择左侧的社区</p>
                </div>
                <!-- 表单 -->
                <data-form ref="data_form" @resetBtn="resetBtn"></data-form>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3.vue"
import DataForm from "./form.vue"
import ElemTable from "../../components/elem-table.vue"

export default {
    components: { LiefengContent, DataForm, ElemTable },

    data() {
        return {
            table_url: null,
            tree_data: [],

            perfectedColumns: [
                {
                    title: "标签名称",
                    key: "labelName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "排序",
                    key: "sort",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "编码",
                    key: "labelCode",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "创建时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.gmtCreate) {
                            return h("div", {}, "-")
                        }

                        return h("div", {}, this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
            ],

            operate: function(h, params) {
                return [
                    h(
                        "DropdownItem",
                        {
                            nativeOn: {
                                click: () => {
                                    this.onRemoveLabel(params.row.id)
                                },
                            },
                            style: {
                                textAlign: "center",
                            },
                        },
                        "移除筛选标签"
                    ),
                ]
            }.bind(this),

            //查询参数
            searchForm: {
                keyword: "",
            },
        }
    },

    async created() {
        // 类型
        this.orgType = this.$route.query.t === "community" ? 2 : 1
        // 初始树形
        this.tree_data = await this.getTreeData(4401)
    },

    methods: {
        /**
         * 获取树形数据
         */
        getTreeData(code) {
            return new Promise(resolve => {
                this.$get("/gateway/apps/org/org/pc/organization/selectOrganizationByOrgCodeV2?orgCode=" + code).then(res => {
                    const list = res.dataList
                    const data = []

                    for (let i = 0; i < list.length; i++) {
                        let e = list[i]
                        data.push({
                            id: e.id,
                            title: e.name,
                            code: e.code,
                            ...(e.child
                                ? {
                                      loading: false,
                                      children: [],
                                  }
                                : {}),
                        })
                    }

                    resolve(data)
                })
            })
        },

        /**
         * 加载树形数据
         */
        onLoadTreeData(e, cb) {
            this.getTreeData(e.code).then(cb)
        },

        /**
         * 选择树形子节点
         */
        onSelectTreeChildren(evt) {
            const e = evt[0]

            if (!e || e.children) return

            this.orgCode = e.code
            this.table_url = `/gateway/api/sycompany/pc/label/getOrgLabelByPage?orgCode=${e.code}&orgType=${this.orgType}`
        },

        // 查询按钮
        searchBtn() {
            this.$refs.elem_table.search(this.searchForm)
        },

        // 重置按钮
        resetBtn() {
            this.$refs.elem_table.reset()
        },

        // 显示表单页面
        onDisplayForm() {
            this.$refs.data_form.open(this.orgCode, this.orgType)
        },

        /**
         * 移除标签
         */
        onRemoveLabel(id) {
            this.$Modal.confirm({
                title: "移除确定",
                content: "您正在对系统进行删除操作、请确保系统下无数据、并且当前操作不可逆转，是否继续？",
                onOk: () => {
                    this.$post("/gateway/api/sycompany/pc/label/deleteOrgLabel", {
                        id: id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "移除成功",
                                background: true,
                            })
                            // 重置表格数据
                            this.$refs.elem_table.reset()
                        } else {
                            this.$Message.error({
                                content: "删除失败",
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}

.jointly-label-community-page {
    .tree-box {
        padding: 10px;
        margin-right: 10px;
        height: 100%;
        float: left;
        width: 200px;
        border: 1px solid #e3e3e3;
        overflow-y: auto;
    }

    .unselected {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
            margin-left: 10px;
            font-size: 22px;
        }
    }
}
</style>
